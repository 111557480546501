import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Table, Pagination, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

function PaymentHistory() {
    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage] = useState(10);
    const navigate = useNavigate();
    const customer_id = localStorage.getItem("stripeCustomerId")

    useEffect(() => {
        const uid = localStorage.getItem("uid");
        if (!uid) {
            navigate('/SignIn');
            return;
        }

        async function fetchPayments() {
            try {
                const response = await fetch(`https://api.stripe.com/v1/payment_intents`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer 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`,
                        'Content-Type': 'application/json'
                    }
                });

                const data = await response.json();
                console.log(data); 
                setTableData(data.data); 
            } catch (error) {
                console.error('Error fetching payment history:', error);
            }
        }

        fetchPayments();
    }, [navigate]);

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = tableData?.slice(indexOfFirstRow, indexOfLastRow);
    const totalPages = Math.ceil(tableData.length / rowsPerPage);

    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

    const downloadInvoice = async (paymentId) => {
        try {
            // First, fetch the payment intent to check if it's succeeded
            const paymentResponse = await fetch(`https://api.stripe.com/v1/payments/${paymentId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer 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`,
                    'Content-Type': 'application/json'
                }
            });
            
            const paymentData = await paymentResponse.json();
    
            if (paymentData.status === "succeeded") {
                // Check if the payment intent has an associated invoice
                if (paymentData.invoice) {
                    const invoiceId = paymentData.invoice;
                    
                    // Fetch the invoice using the invoice ID
                    const invoiceResponse = await fetch(`https://api.stripe.com/v1/invoices/${invoiceId}`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer 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`,
                            'Content-Type': 'application/json'
                        }
                    });
                    console.log("jjj",invoiceData);

                    const invoiceData = await invoiceResponse.json();
    
                    // If an invoice is found, download the PDF
                    if (invoiceData.invoice_pdf) {
                        const invoicePdfUrl = invoiceData.invoice_pdf;
                        const link = document.createElement('a');
                        link.href = invoicePdfUrl;
                        link.download = `invoice_${paymentId}.pdf`;
                        link.click();
                    } else {
                        alert('Invoice not available for this payment.');
                    }
                } else {
                    alert('No invoice created for this payment.');
                }
            } else {
                alert('Payment has not been completed successfully.');
            }
        } catch (error) {
            console.error('Error fetching invoice:', error);
            alert('There was an error fetching the invoice.');
        }
    };
    
    

    return (
        <>
            <Helmet>
                <title>Customer Payment History</title>
            </Helmet>
            <Container>
                <br />
                <br />
                <br />
                <br />
                <br />

                <Row className='my-3 tableShowList'>
                    <Col>
                        <Table hover>
                            <thead>
                                <tr>
                                    <th>Payment ID</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Date</th>
                                    <th>Invoice</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentRows?.map((payment, index) => (
                                    <tr key={index}>
                                        <td>{payment.id}</td>
                                        <td>{payment.customer}</td>

                                        <td>${(payment.amount / 100).toFixed(2)}</td>
                                        <td>{payment.status === "succeeded" ? "Paid" : "Unpaid"}</td>
                                        <td>{new Date(payment.created * 1000).toLocaleString()}</td>
                                        <td>
                                            {payment.status === "succeeded" && (
                                                <Button onClick={() => downloadInvoice(payment.id)} variant="primary">
                                                    Download Invoice
                                                </Button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <Pagination>
                            <Pagination.Prev disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)} />
                            {[...Array(totalPages).keys()].map((page) => (
                                <Pagination.Item key={page} active={page + 1 === currentPage} onClick={() => handlePageChange(page + 1)}>
                                    {page + 1}
                                </Pagination.Item>
                            ))}
                            <Pagination.Next disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)} />
                        </Pagination>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default PaymentHistory;

import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Footer from './Footer';
import axios from 'axios';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Helmet } from 'react-helmet';

function CallCenterRegistration() {
    const navigate = useNavigate();
    const auth = getAuth();
    const apiKey = process.env.REACT_APP_API_KEY;

    const [user, setUser] = useState({
        companyName: "",
        companyPhoneNumber: "",
        companyEmail: "",
        companyWebsiteOrSocialMedia: "",
        principalFirstName: "",
        principalLastName: "",
        principalPhoneNumber: "",
        principalEmail: "",
        userName: ""
    });

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                navigate('/'); 
            }
        });

        return () => unsubscribe();
    }, [navigate, auth]);

    const onInputChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const onPhoneInputChange = (value, name) => {
        setUser({ ...user, [name]: value });
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        try {
            const payload = {
                companyName: user.companyName,
                companyPhoneNumber: user.companyPhoneNumber,
                companyEmail: user.companyEmail,
                companyWebsiteOrSocialMedia: user.companyWebsiteOrSocialMedia,
                principalFirstName: user.principalFirstName,
                principalLastName: user.principalLastName,
                principalPhoneNumber: user.principalPhoneNumber,
                principalEmail: user.principalEmail,
                userName: user.userName
            };

            const response = await axios.post("https://api.v2.badskip.com/callcenter/register", payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': apiKey
                }
            });

            if (response.data) {
                Swal.fire({
                    title: `<h3 class='thanksheading' style="color: #EF3F40; font-weight: 600; font-size: 32px;">Thank you for submitting the needed information.</h3>
                <p class='thankspara' style="color: #000; font-weight: 500; line-height: 16px; font-size: 22px;">Your account is currently pending approval. <br />
                Our team will be reaching out <br />
                on e-mail in 1~3 business days to <br />
                notify you with the status. </p>`,
                    showConfirmButton: true,
                    confirmButtonText:"Understood",
                    customClass: {
                        confirmButton: 'my-confirm-button', 
                    }
                });
                navigate('/');
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Registration failed',
                    text: 'Please try again.',
                    customClass: {
                        confirmButton: 'my-confirm-button', 
                    }
                });
            }
        } catch (error) {
            console.error("Error during registration:", error);
            Swal.fire({
                icon: 'error',
                title: 'Registration failed',
                customClass: {
                    confirmButton: 'my-confirm-button', 
                },
                text: error.response.data.message
            });
        }
    };

    return (
        <>
            <Helmet>
                <title>BadSkip | Call Center Registration </title>
            </Helmet>
            <section className="homeFourth formSection account">
                <h2>Call Center Registration</h2>
                <Container fluid>
                    <Row>
                        <Col lg={6} md={6} sm={12}>
                            <div className="formDiv">
                                <form method="post" onSubmit={(e) => onSubmit(e)}>
                                    <div>
                                        <input className="form-control" name="companyName"
                                            value={user.companyName}
                                            onChange={(e) => onInputChange(e)} type='text' placeholder="Company Name" />
                                    </div>
                                    <div>
                                        <PhoneInput
                                            country={"us"}

                                            value={user.companyPhoneNumber}
                                            onChange={(value) => onPhoneInputChange(value, 'companyPhoneNumber')}
                                            inputProps={{
                                                name: 'companyPhoneNumber',
                                                required: true,
                                                autoFocus: true
                                            }}
                                            placeholder='company Phone Number'
                                            containerClass="phone-input-container"
                                            inputClass="phone-input"
                                            buttonClass="phone-input-button"
                                        />
                                    </div>
                                    <div>
                                        <input className="form-control" name="companyEmail"
                                            value={user.companyEmail}
                                            onChange={(e) => onInputChange(e)} type='email' placeholder="Company Email" />
                                    </div>
                                    <div>
                                        <input className="form-control" name="userName"
                                            value={user.userName}
                                            onChange={(e) => onInputChange(e)} type='text' placeholder="Username" />
                                    </div>
                                    <div>
                                        <input className="form-control" name="companyWebsiteOrSocialMedia"
                                            value={user.companyWebsiteOrSocialMedia}
                                            onChange={(e) => onInputChange(e)} type='text' placeholder="Company's Website/ Social Media Links" />
                                    </div>

                                    <div>
                                        <input className="form-control" name="principalFirstName"
                                            value={user.principalFirstName}
                                            onChange={(e) => onInputChange(e)} type='text' placeholder="Principal's First Name" />
                                    </div>
                                    <div>
                                        <input className="form-control" name="principalLastName"
                                            value={user.principalLastName}
                                            onChange={(e) => onInputChange(e)} type='text' placeholder="Principal's Last Name" />
                                    </div>
                                
                                    <div>
                                        <PhoneInput
                                            country={"us"}

                                            value={user.principalPhoneNumber}
                                            onChange={(value) => onPhoneInputChange(value, 'principalPhoneNumber')}
                                            inputProps={{
                                                name: 'principalPhoneNumber',
                                                required: true
                                            }}
                                            placeholder='principal Phone Number'

                                            containerClass="phone-input-container"
                                            inputClass="phone-input"
                                            buttonClass="phone-input-button"
                                        />
                                    </div>
                                    <div>
                                        <input className="form-control" name="principalEmail"
                                            value={user.principalEmail}
                                            onChange={(e) => onInputChange(e)} type='email' placeholder="Principal's Email " />
                                    </div>
                                    <div>
                                        <button type="submit">Sign Up</button>
                                    </div>
                                    <div className='haveAccount'>
                                        <p>Already have an account?</p><Link to="/SignIn"> Login now!</Link>
                                    </div>
                                </form>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <img src="images/callcenter.png" alt="Call Center" />
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    )
}

export default CallCenterRegistration;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions"; 

const firebaseConfig = {
  apiKey: "AIzaSyAox5YfPJw7YL4X_PT3uK4dXcX5Kf8FEAA",
  authDomain: "bad-skip-db.firebaseapp.com",
  projectId: "bad-skip-db",
  storageBucket: "bad-skip-db.appspot.com",
  messagingSenderId: "593615586665",
  appId: "1:593615586665:web:6f35a89a608aade58c69f8"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app); 
const storage = getStorage(app);
const functions = getFunctions(app); 

export { auth, db, app , storage , functions}; // Export Firestore
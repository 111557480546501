import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Footer from './Footer'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

function BuildVa() {

    return (
        <>
            <Helmet>
                <title>BadSkip | Build a Top-Performing VA Team
                </title>
            </Helmet>
            <section className="homeFourth buildva">
                <h2>Build Your VA’s Team
                    <br />
                    “VA Team Creation”
                </h2>
                <Container>
                    <Row>
                        <Col lg={5} md={5} sm={12} className="imgmob">

                            <img src="images/business-people-stacking-their-hands.png" alt="build-va-team" />

                        </Col>
                        <Col lg={7} md={7} sm={12}>
                            <div className="rightfourth">
                                <h3>Build Your Dream Team</h3>
                                <h4>“ Right People, Right Results”</h4>
                                <p>
                                    Building a high-performing outreach team isn’t just
                                    about hiring, it’s about finding the right people,
                                    giving them the right tools, and ensuring they’re
                                    focused on the right tasks. knowing exactly what are
                                    the criteria that they should follow.

                                </p>
                                <p>
                                    At <span> BadSkip</span> , we take care of the entire process, from
                                    recruitment to daily management, so you can focus
                                    on growing your business.

                                </p>

                            </div>
                        </Col>


                    </Row>
                </Container>
            </section>
            <section className="homeSecond buildva">
                <Container>
                    <h2>What You Can Get With <span> BadSkip</span></h2>
                    <p>
                        <span> Recruitment: </span> We source virtual assistants who are experienced enough and have the skills
                        <br />
                        for your industry.

                        Our rigorous selection process ensures the best cut.

                    </p>
                    <p>
                        <span> Training: </span> Once onboard, our team leaders provide specialized training tailored to
                        <br />

                        your business needs. Whether it’s lead generation, customer outreach, or data entry,
                        <br />

                        we make sure your VAs are ready to hit the ground running.

                    </p>
                    <p>
                        <span> Daily Management: </span> Managing a remote team can be challenging, but we’ve got it covered.
                        <br />

                        From setting daily goals to tracking performance, we ensure that your VAs are always
                        <br />

                        operating at peak efficiency.

                    </p>
                    <p className='text-center'>
                        Because your business deserves the best. With our VA team creation service,
                        <br />

                        you get more than just a set of hands—you get a dedicated team, tailored to
                        <br />

                        your needs and managed to deliver results.
                    </p>
                    <Link to="/SubmitInquiry">
                        <button>Build Your VA Team Now</button>
                    </Link>
                </Container>
            </section>
            <Footer />
        </>
    )
}

export default BuildVa

import React, { useState } from 'react'
import Footer from "./Footer";
import { Col, Container, Row } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import Swal from "sweetalert2";
import { db } from "./firebase";
import { doc, setDoc, addDoc, collection } from "firebase/firestore";
import { useLocation, useNavigate } from 'react-router-dom';

const Preloader = () => (
    <div class="loader"></div>
);
function ContactPricing() {
    const [formData, setFormData] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };
    const location = useLocation();
    // const { price, credits, uid, subscriptionType } = location.state || {};
    // console.log("dddd",location.state.price, location.state.credits);
    const navigate = useNavigate("")
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);

        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleString("en-US");
        const year = new Date(formattedDate).getFullYear();
        const month = (new Date(formattedDate).getMonth() + 1)
            .toString()
            .padStart(2, "0");
        const day = new Date(formattedDate).getDate().toString().padStart(2, "0");

        const dateWithoutAmPm = formattedDate.slice(0, -3);
        const amPm = formattedDate.slice(-3);
        const milliseconds = currentDate
            .getMilliseconds()
            .toString()
            .padStart(3, "0");

        const formattedDateWithMilliseconds = `${dateWithoutAmPm}.${milliseconds}${amPm}`;

        const uid = formattedDateWithMilliseconds
        .replaceAll("/", "_")
        .replaceAll(":", "-")
        .replace(", ", "@")
        .replace(" ", "#");

        const docName = `${day}_${month}_${year}`;



        const submitData = {
            [uid]: formData,
        };

        await setDoc(doc(db, "messages", docName), submitData, {
            merge: true,
        });
        const emailHtml = `Hey ${formData.name},<br/>We have received your Request submitted via our website. Our team is on it and will get back to you as soon as possible. If you have any questions in the meantime, feel free to reach out by replying to this email.<br/><br/>Thank you,<br/><br/>`;
        const signatureHtml = `  <font color="#888888">
            <span class="gmail_signature_prefix">-- </span><br>
            <div dir="ltr" class="gmail_signature" data-smartmail="gmail_signature">
                <div dir="ltr">
                    <table class="signature-table" width="414" cellpadding="0" cellspacing="0" border="0">
                        <tbody>
                            <tr>
                                <td valign="top" class="signature-cell">
                                    <table border="0" cellpadding="0" cellspacing="0" width="414" class="inner-table">
                                        <tbody>
                                            <tr>
                                            <!--
                                                <td class="no-padding">
                                                    <span class="name-title"><font face="arial black, sans-serif">Samir H</font></span>
                                                </td>
                                                -->
                                                <td class="no-padding"></td>
                                            </tr>
                                            <tr>
                                                <td class="no-padding">
                                                    <span class="position-title"><font face="arial black, sans-serif">Client Success Manager<br><br></font></span>
                                                </td>
                                                <td align="right" width="170" class="no-padding">
                                                    <p dir="ltr" class="social-links">
                                                        <span>
                                                            <a href="http://badskip.com/" target="_blank">
                                                                <img src="https://lh7-us.googleusercontent.com/ERQye9fHlkGU5k3qeAOaIPlXt6WEXXaU3Qk6sX-RPc989aGEznY7hiAxlQrZZy4jbAFXGrMQpBM7XooSYiCJQoTXjmwx2feUNpI2IMZAFp4rcsQCUmyJTDeeYB7wXVKx2ajwEOROluc4SDSAovfxy44" width="23" height="23" alt="Website" class="icon">
                                                            </a>&nbsp;
                                                        </span>
                                                        <span>
                                                            <a href="https://www.instagram.com/badskip/" target="_blank">
                                                                <img src="https://lh7-us.googleusercontent.com/RBHXzA6EarsjkC28LnCKIOX46gSouRWQvToQulLKq_T8amnmvJz3GO1Pd5GML1bps41dLRSlJe8y3UR6olRNRWBBzflLsAPtb2KI9n6PynABhc6WXrH3CrSimE6b-7cn4ntkAr6EnTkr2i0Qw9QQr0w" width="23" height="23" alt="Instagram" class="icon">
                                                            </a>&nbsp;
                                                        </span>
                                                       <!--

                                                        <span>
                                                            <a href="https://wa.me/15619225376" target="_blank">
                                                                <img src="https://lh7-us.googleusercontent.com/Rgf8cTDfganYaSmVeRG8an1XXfBmeubjqi1eCPjKsprqPPgN6A-isjGF-TvzNNacha4ut_sMzFYXo3DbOEMb9SIcp4vA1kKnvykcTww8iV_wh2L6GsyVb9aTQiZO5e7m-2hESYGqdtbnOFF8WJBWuUg" width="23" height="23" alt="WhatsApp" class="icon">
                                                            </a>&nbsp;
                                                        </span>
                                                        <span>
                                                            <a href="https://www.linkedin.com/company/100955777" target="_blank">
                                                                <img src="https://lh7-us.googleusercontent.com/0554XXBDxwmR3G1JA7ijlqWufEnXfK_l1LUpuqg-eydQVjai3t0GXbVp1xrqfvKH1g1thL1EzqjUpMqvwITbTb7ndmCv1125NqDuBN45wsCQkCB17bxXIpAEfbCMv_G2WQwpAqFp6vIBmNo3uqgzKMI" width="23" height="23" alt="LinkedIn" class="icon">
                                                            </a>
                                                        </span>
                                                        -->
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                            
                                                <td class="no-padding">
                                                    <span class="contact-info">
                                                       <!--

                                                        <span class="icon-cell">
                                                            <img src="https://lh7-us.googleusercontent.com/qv5uavYFMPAfRw6bWUk8GYOV-ngLGdu4ndYfyql8dq8Cogw2Jfgm4IZhbUIyj50D0kNLCA-J8mF_YCkw5RrKm-6p3cQog0MTiVkR6WE_EK5fcMgLP9ufvtzS_N87-tNRUJuucuG9sNvShAplf9oDnW4" width="19" height="19" class="contact-icon">
                                                        </span>
                                                        <font face="arial black, sans-serif">(310) 601-8347</font>
                                                        <br>
                                                        <span class="icon-cell">
                                                            <img src="https://lh7-us.googleusercontent.com/d6sEan4jectEq-6hIecRokXTA4nHSmFDVk0v71K5EXwxoepvfnMyxoNfUhrrTlVOLbssr81WHwRaBO-IhvFHOrD2vkl0gsB7nmxPznFc_YGxFTRiwwvhQMgxlr4i3mPJ2gz9SSNdsbZFLQMRs0JZzQo" width="19" height="19" class="contact-icon">
                                                        </span>&nbsp;
                                                        -->
                                                        <a href="mailto:support@badskip.com" class="email-link">support@badskip.com</a>&nbsp; &nbsp; &nbsp;
                                                    </span>
                                                </td>
                                                <td valign="bottom" align="right" class="no-padding"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                       <!--

                            <tr>
                                <td class="banner-cell">

                                    <a href="https://badskip.com/" target="_blank">
                                        <img border="0" alt="Banner" width="414" src="https://ci3.googleusercontent.com/meips/ADKq_Nao5PJGW-cu5EO2By_8Ke9Dcl1rL90Pfc7dZ7nzBIH9qx9rk_HYSLsB2oqY6rLLWq_UIJ-n=s0-d-e1-ft#https://i.imgur.com/u495xLU.png" class="banner-image">
                                    </a>
                                </td>
                            </tr>
                            -->
                            <tr>
                                <td class="disclaimer-cell">
                                    The content of this email is confidential and intended for the recipient specified in message only. It is strictly forbidden to share any part of this message with any third party, without a written consent of the sender. If you received this message by mistake, please reply to this message and follow with its deletion, so that we can ensure such a mistake does not occur in the future.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </font>`;
        const fullHtml = emailHtml + signatureHtml;

        addDoc(collection(db, "mail"), {
            to: formData.email,
            message: {
                subject: "badskip | We Have Received Your Message!",
                html: fullHtml,
            },
        });
       if(location.state.subscriptionType){
        await addDoc(collection(db, "mail"), {
            to: "support@badskip.com",
            
            message: {
                subject: "New Message Submitted",
                html: `<code><h1>New Message</h1><h2>Name: ${formData.name}</h2><h2>Email: ${formData.email}</h2><h2>Phone: ${formData.phone}</h2><h2>Message: ${formData.message}</h2><h2>Subscription Type: ${location.state.subscriptionType}</h2><h2>UID: ${location.state.uid}</h2>   </code>`,
            },
        });
       }else{
        await addDoc(collection(db, "mail"), {
            to: "support@badskip.com",
            message: {
                subject: "New Request Submitted",
                html: `<code><h1>New Request</h1><h2>Name: ${formData.name}</h2><h2>Email: ${formData.email}</h2><h2>Phone: ${formData.phone}</h2><h2>Message: ${formData.message}</h2><h2>Credits: ${location.state.credits}</h2><h2>Price: ${location.state.price}</h2></code>`,
            },
        });
       }
   

       event.target.reset()
        Swal.fire({
            icon: 'success',
            title: 'Sent successfully',
            text: "Message Sent successfully ",
            timer: 1000,
            customClass: {
                confirmButton: 'my-confirm-button', 
            }

        }).then(handleNewInquiry).then(navigate("/Pricing"))


    };

    const handleNewInquiry = () => {
        setFormData({});
        setIsSubmitting(false);

    };
  return (
    <>
        <section className="homeFourth formSection" id="Contact">
                <h2>Buy Credits :</h2>
                <Container fluid>
                    <Row>
                        <Col lg={6} md={6} sm={12}>

                            <img src="images/handsome-young-man-working-with-laptop-mobile-phone-office.png" alt="contact" />

                        </Col>
                        <Col lg={6} md={6} sm={12}>
                            <div className="formDiv">
                            
                                <form onSubmit={handleSubmit}>
                                    <h3 className='mb-5'>Buy Credits</h3>
                                    <div>
                                        <input
                                            className="form-control"
                                            name="name"
                                            placeholder="Name"
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div>
                                        <input
                                            className="form-control"
                                            name="email"
                                            placeholder="Email Address"
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div>
                                        <PhoneInput
                                        country={"us"}
                                            inputProps={{
                                                name: 'phone',
                                                required: true,
                                            }}
                                            inputClass="form-control"
                                            onChange={(value) => handleChange({ target: { name: 'phone', value } })}
                                            placeholder="Phone Number"
                                        />
                                    </div>
                                    <div>
                                        <textarea
                                            name="message"
                                            className="form-control"
                                            placeholder="Message"
                                            onChange={handleChange}
                                        ></textarea>
                                    </div>
                                    <div>
                                    <button type="submit" disabled={isSubmitting}>  {isSubmitting ? <Preloader /> : "Submit"}</button>

                                    </div>
                                </form>

                            </div>
                        </Col>


                    </Row>
                </Container>
            </section>
            <Footer />
    </>
  )
}

export default ContactPricing

import React, { useState } from 'react'
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "./firebase";
import Swal from 'sweetalert2';
import { Col, Container, Row } from 'react-bootstrap';
import Footer from './Footer';
import { Helmet } from 'react-helmet';

function ForgetPassword() {
    const [email, setEmail] = useState('');


    const handleForgotPassword = async (e) => {
        e.preventDefault();
        try {
            // Send the password reset email
            await sendPasswordResetEmail(auth, email, {
                url: 'http://localhost:3000/ResetPassword?oobCode=YOUR_OOB_CODE' // URL where users will be redirected
            });
            Swal.fire({
                icon: 'success',
                title: 'Password reset link sent!',
                text: 'Check your email for the reset link.',
                customClass: {
                    confirmButton: 'my-confirm-button', 
                }
            });
        } catch (error) {
            console.error("Error sending password reset email:", error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                customClass: {
                    confirmButton: 'my-confirm-button', 
                }
            });
        }
    };

    return (
        <>
            <Helmet>
                    <title>BadSkip | Forget Password </title>
                </Helmet>
            <section className="homeFourth formSection account">
                <h2>Forget Password?</h2>
                <Container>
                    <Row>
                        <Col lg={11} md={11} sm={12}>
                            <div className="formDiv mt-5">
                                <form onSubmit={handleForgotPassword}>
                                    <input
                                        type="email"
                                        className='form-control'
                                        placeholder="Enter Your Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                    <button type="submit">Send Reset Link</button>
                                </form>
                            </div>
                        </Col>
                    
                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    )
}

export default ForgetPassword
